import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const newLeague = async (
  seasonId: any,
  name: any,
  description: any,
  photo: any,
  teamLimit: any,
  teamSize: any,
  startDate: any,
  regularWeeksNumberOfWeeks: any,
  regularWeeksWeekLength: any,
  regularWeeksFlexibleOffDaysNumber: any,
  playoffWeeksBracketSize: any,
  playoffWeeksNumberOfWeeks: any,
  isPrivate: any,
  createPlaceholderTeams: any,
  dailyMax: any,
  teamBonus: any,
  endHour: any,
  timezone: any,
  bonusesWater: any,
  bonusesAlcohol: any,
  bonusesJunkFood: any,
  workoutSource: any
) => {
  try {
    const data = {
      name,
      photo,
      description,
      teamSize,
      teamLimit: Number(teamLimit),
      regularWeeks: {
        numberOfWeeks: Number(regularWeeksNumberOfWeeks),
        weekLength: Number(regularWeeksWeekLength),
        flexibleOffDaysNumber: Number(regularWeeksFlexibleOffDaysNumber),
      },
      playoffWeeks: { bracketSize: Number(playoffWeeksBracketSize), numberOfWeeks: Number(playoffWeeksNumberOfWeeks) },
      privateLeague: isPrivate,
      startDate,
      endHour,
      timezone,
      dailyMax: Number(dailyMax),
      teamBonus: Number(teamBonus),
      bonuses: { water: bonusesWater, alcohol: bonusesAlcohol, junkFood: bonusesJunkFood },
      createPlaceholderTeams,
      workoutSource
    };

    const response = await fetch(`${API_URL}/season/${seasonId}/league`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ newLeague ~ error', error);
    return { status: 500 };
  }
};

const getLeague = async (seasonId: string, id: string) => {
  try {
    const response = await fetch(`${API_URL}/season/${seasonId}/league/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ getLeague ~ error', error);
    return { status: 500 };
  }
};

const getLeagueStandings = async (seasonId: string, id: string) => {
  try {
    const response = await fetch(`${API_URL}/season/${seasonId}/league/${id}/standings`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ getLeague ~ error', error);
    return { status: 500 };
  }
};

const editLeague = async (
  seasonId: any,
  leagueId: any,
  name: any,
  description: any,
  photo: any,
  teamLimit: any,
  teamSize: any,
  startDate: any,
  regularWeeksNumberOfWeeks: any,
  regularWeeksWeekLength: any,
  regularWeeksFlexibleOffDaysNumber: any,
  playoffWeeksBracketSize: any,
  playoffWeeksNumberOfWeeks: any,
  isPrivate: any,
  createPlaceholderTeams: any,
  dailyMax: any,
  teamBonus: any,
  endHour: any,
  timezone: any,
  bonusesWater: any,
  bonusesAlcohol: any,
  bonusesJunkFood: any,
  workoutSource: any
) => {
  try {
    const data = {
      name,
      photo,
      description,
      teamSize,
      teamLimit: Number(teamLimit),
      regularWeeks: {
        numberOfWeeks: Number(regularWeeksNumberOfWeeks),
        weekLength: Number(regularWeeksWeekLength),
        flexibleOffDaysNumber: Number(regularWeeksFlexibleOffDaysNumber),
      },
      playoffWeeks: { bracketSize: Number(playoffWeeksBracketSize), numberOfWeeks: Number(playoffWeeksNumberOfWeeks) },
      privateLeague: isPrivate,
      startDate,
      endHour,
      timezone,
      dailyMax: Number(dailyMax),
      teamBonus: Number(teamBonus),
      bonuses: { water: bonusesWater, alcohol: bonusesAlcohol, junkFood: bonusesJunkFood },
      createPlaceholderTeams,
      workoutSource
    };

    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ editLeague ~ error', error);
    return { status: 500 };
  }
};

const getLeaguesForMovingTeam = async (seasonId: string, leagueId: string) => {
  try {
    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}/moving_list`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ getLeaguesForMovingTeam ~ error', error);
    return { status: 500 };
  }
};

const moveTeamToLeague = async (seasonId: string, leagueId: string, teamId: string, newLeagueId: string) => {
  const data = {
    teamId,
    newLeagueId,
  };

  try {
    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}/move`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ moveTeamToLeague ~ error', error);
    return { status: 500 };
  }
};

const removeTeam = async (seasonId: string, leagueId: string, teamId: string) => {
  try {
    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}/team/${teamId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ removeTeam ~ error', error);
    return { status: 500 };
  }
};

const newDemoLeague = async (period: string) => {
  try {
    const response = await fetch(`${API_URL}/test/league`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify({ period })
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ newDemoLeague ~ error', error);
    return { status: 500 };
  }
};

const getDemoLeague = async () => {
  try {
    const response = await fetch(`${API_URL}/test/league`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ getDemoLeague ~ error', error);
    return { status: 500 };
  }
};

const removeDemoLeague = async () => {
  try {
    const response = await fetch(`${API_URL}/test/league`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: LeaguesService.js ~ removeDemoLeague ~ error', error);
    return { status: 500 };
  }
};

export const LeaguesService = {
  newLeague,
  getLeague,
  getLeagueStandings,
  editLeague,
  getLeaguesForMovingTeam,
  moveTeamToLeague,
  removeTeam,
  newDemoLeague,
  getDemoLeague,
  removeDemoLeague,
};
