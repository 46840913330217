import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { listTimeZones } from 'timezone-support';
import { SessionService, LeaguesService, SeasonsService } from '../../services';
import { Sidebar, Switch, FormInput, FormTextArea, UploadPhoto, DropDown } from '../../components';

const LeagueNew = () => {
  const navigate = useNavigate();
  const { seasonId } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState('');
  const [teamLimit, setTeamLimit] = useState(0);
  const [teamSize, setTeamSize] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [regularWeeksNumberOfWeeks, setRegularWeeksNumberOfWeeks] = useState('');
  const [regularWeeksWeekLength, setRegularWeeksWeekLength] = useState('');
  const [regularWeeksFlexibleOffDaysNumber, setRegularWeeksFlexibleOffDaysNumber] = useState('');
  const [playoffWeeksBracketSize, setPlayoffWeeksBracketSize] = useState('');
  const [playoffWeeksNumberOfWeeks, setPlayoffWeeksNumberOfWeeks] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [createPlaceholderTeams, setCreatePlaceholderTeams] = useState(false);
  const [dailyMax, setDailyMax] = useState('');
  const [teamBonus, setTeamBonus] = useState('');
  const [endHour, setEndHour] = useState('');
  const [timezone, setTimezone] = useState('');
  const [bonusesWater, setBonusesWater] = useState(false);
  const [bonusesAlcohol, setBonusesAlcohol] = useState(false);
  const [bonusesJunkFood, setBonusesJunkFood] = useState(false);
  const [workoutSource, setWorkoutSource] = useState('Moove');

  // populate dropdowns list for timezones
  const timeZoneList = [];
  for (const timeZone of listTimeZones()) {
    timeZoneList.push({
      key: timeZone,
      value: timeZone,
    });
  }

  useEffect(() => {
    const getData = async () => {
      const data = await SeasonsService.getSeasonDetails(seasonId, 0, 20);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.season) {
        setIsPrivate(() => data.season.privateSeason);
      }
    };

    getData();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !seasonId ||
      !name ||
      !teamLimit ||
      !teamSize ||
      !startDate ||
      !regularWeeksNumberOfWeeks ||
      !regularWeeksWeekLength ||
      !regularWeeksFlexibleOffDaysNumber ||
      !playoffWeeksBracketSize ||
      !playoffWeeksNumberOfWeeks ||
      !dailyMax ||
      !endHour ||
      !timezone ||
      !workoutSource
    ) {
      alert('Please fill in all the required fields.');
      return;
    }

    const data = await LeaguesService.newLeague(
      seasonId,
      name,
      description,
      photo,
      teamLimit,
      teamSize,
      startDate,
      regularWeeksNumberOfWeeks,
      regularWeeksWeekLength,
      regularWeeksFlexibleOffDaysNumber,
      playoffWeeksBracketSize,
      playoffWeeksNumberOfWeeks,
      isPrivate,
      createPlaceholderTeams,
      dailyMax,
      teamBonus,
      endHour,
      timezone,
      bonusesWater,
      bonusesAlcohol,
      bonusesJunkFood,
      workoutSource
    );

    if (data.errorCode === 2) {
      alert('Please fill in all the required fields.');
    }

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      navigate(`/seasons/${seasonId}`);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h1 className="text-xl font-semibold">New League</h1>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-3 gap-4">
                  <div className="form-section">
                    <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Format</h2>
                    <UploadPhoto initPhoto={photo} onSubmit={url => setPhoto(url)} />
                    <FormInput
                      label="Name"
                      type="text"
                      placeholder="e.g. Awesome League"
                      required={true}
                      onChange={text => setName(text)}
                    />
                    <FormTextArea
                      label="Description"
                      placeholder="e.g. Some superawesome league description"
                      onChange={text => setDescription(text)}
                    />
                    <FormInput
                      label="Team limit"
                      type="number"
                      placeholder="e.g 16"
                      required={true}
                      onChange={text => setTeamLimit(parseInt(text, 10))}
                    />
                    <FormInput
                      label={`Format ${teamSize ? `(${teamSize} vs. ${teamSize})` : ''}`}
                      type="number"
                      placeholder="e.g 2"
                      required={true}
                      onChange={text => setTeamSize(parseInt(text, 10))}
                    />
                    <FormInput
                      label="Start date"
                      type="date"
                      placeholder={'e.g. ' + new Date().toISOString().split('T')[0]}
                      min={new Date().toISOString().split('T')[0]}
                      required={true}
                      onChange={text => setStartDate(text)}
                    />
                    {/* <Switch title="Is private?" value={isPrivate} onChange={selected => setIsPrivate(selected)} /> */}
                  </div>

                  <div className="form-section">
                    <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Season play</h2>
                    <h3 className="mb-4 text-xs">
                      Teams take each other on in a weeklong competition to rais up the rankings
                      <ul className="ml-4 list-disc list-inside">
                        <li className="mt-2">Weeks begin Monday and can end either Friday, Saturday or Sunday</li>
                        <li>Admins can give users the ability to take a Flexible day-off during the week</li>
                      </ul>
                    </h3>
                    <div className="space-y-2">
                      <FormInput
                        label="Weeks in Regular Season"
                        type="number"
                        placeholder="e.g 6"
                        max={10}
                        required={true}
                        onChange={text => setRegularWeeksNumberOfWeeks(text)}
                      />
                      <DropDown
                        label="Week length"
                        placeholder="Please choose week length"
                        value={regularWeeksWeekLength}
                        values={[
                          {
                            key: '7',
                            value: '7 days',
                          },
                          {
                            key: '6',
                            value: '6 days',
                          },
                          {
                            key: '5',
                            value: '5 days',
                          },
                        ]}
                        required={true}
                        onChange={text => setRegularWeeksWeekLength(text)}
                      />
                      <DropDown
                        label="# of Flexible Off-Days"
                        placeholder="Please choose number of flexible off-days"
                        value={regularWeeksFlexibleOffDaysNumber}
                        values={[
                          {
                            key: '2',
                            value: '2 days',
                          },
                          {
                            key: '1',
                            value: '1 day',
                          },
                          {
                            key: '0',
                            value: 'No flexible off-days',
                          },
                        ]}
                        required={true}
                        onChange={text => setRegularWeeksFlexibleOffDaysNumber(text)}
                      />
                    </div>
                  </div>

                  <div className="form-section">
                    <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Playoffs</h2>
                    <h3 className="mb-4 text-xs">Highest ranked teams enter Knockout style</h3>
                    <div className="space-y-2">
                      <DropDown
                        label="Bracket size"
                        placeholder="Please choose bracket size"
                        value={playoffWeeksBracketSize}
                        values={[
                          {
                            key: '8',
                            value: '8 teams',
                          },
                          {
                            key: '4',
                            value: '4 teams',
                          },
                        ]}
                        required={true}
                        onChange={text => setPlayoffWeeksBracketSize(text)}
                      />

                      {playoffWeeksBracketSize === '4' && <h3 className="mb-4 text-xs">Semi finals & Finals</h3>}

                      {playoffWeeksBracketSize === '8' && <h3 className="mb-4 text-xs">Quarter finals, Semi finals & Finals</h3>}

                      <DropDown
                        label="Weeks in Playoffs"
                        placeholder="Please choose weeks in playoffs"
                        value={playoffWeeksNumberOfWeeks}
                        values={[
                          {
                            key: '2',
                            value: '2 weeks',
                          },
                          {
                            key: '1',
                            value: '1 week',
                          },
                        ]}
                        required={true}
                        onChange={text => setPlayoffWeeksNumberOfWeeks(text)}
                      />

                      {playoffWeeksBracketSize === '4' && playoffWeeksNumberOfWeeks === '1' && (
                        <>
                          <h3 className="mb-4 text-xs">Semi finals: Monday + Tuesday</h3>
                          <h3 className="mb-4 text-xs">Finals: Thursday + Friday</h3>
                        </>
                      )}

                      {playoffWeeksBracketSize === '4' && playoffWeeksNumberOfWeeks === '2' && (
                        <>
                          <h3 className="mb-4 text-xs">Semi finals: Week 1</h3>
                          <h3 className="mb-4 text-xs">Finals: Week 2</h3>
                        </>
                      )}

                      {playoffWeeksBracketSize === '8' && playoffWeeksNumberOfWeeks === '1' && (
                        <>
                          <h3 className="mb-4 text-xs">Quarter finals: Monday + Tuesday</h3>
                          <h3 className="mb-4 text-xs">Semi finals: Wednesday + Thursday</h3>
                          <h3 className="mb-4 text-xs">Finals: Friday</h3>
                        </>
                      )}

                      {playoffWeeksBracketSize === '8' && playoffWeeksNumberOfWeeks === '2' && (
                        <>
                          <h3 className="mb-4 text-xs">Quarter finals: Monday + Tuesday</h3>
                          <h3 className="mb-4 text-xs">Semi finals: Thursday + Friday</h3>
                          <h3 className="mb-4 text-xs">Finals: Week 2</h3>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="form-section">
                    <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Scoring</h2>
                    <h3 className="mb-4 text-xs">
                      Players will be able to enter scores between 00:00AM and 11:59PM according to League timezone
                      <ul className="ml-4 list-disc list-inside">
                        <li className="mt-2">Scoring deadline can be changed for last day of all matchups</li>
                        <li>Players can optionally gain additional points for bonuses listed below</li>
                        <li>Team can gain a daily bonus if every teammate gets 10 10pts (or declares a day off)</li>
                      </ul>
                    </h3>
                    <div className="space-y-2">
                      <FormInput
                        label="Daily max"
                        type="number"
                        placeholder="e.g 50"
                        required={true}
                        onChange={text => setDailyMax(text)}
                      />
                      <FormInput
                        label="Team bonus"
                        type="number"
                        placeholder='Team "Get to 10" bonus'
                        required={true}
                        onChange={text => setTeamBonus(text)}
                      />
                      <DropDown
                        label="Last day (hour) deadline"
                        placeholder="Please choose scoring deadline end hour"
                        value={endHour}
                        values={[
                          {
                            key: '23:59',
                            value: '11:59 PM',
                          },
                          {
                            key: '21:00',
                            value: '9:00 PM',
                          },
                          {
                            key: '18:00',
                            value: '6:00 PM',
                          },
                        ]}
                        required={true}
                        onChange={text => setEndHour(text)}
                      />
                      <h3 className="mb-4 text-xs">Only applicable on the last day of every matchup.</h3>
                      <DropDown
                        label="Scoring timezone"
                        placeholder="Please choose a timezone"
                        value={timezone}
                        values={timeZoneList}
                        required={true}
                        onChange={text => setTimezone(text)}
                      />
                      <Switch
                        title="Water (+1pt for drinking 64 Oz)"
                        value={bonusesWater}
                        onChange={selected => setBonusesWater(selected)}
                      />
                      <Switch title="No alcohol (+1pt)" value={bonusesAlcohol} onChange={selected => setBonusesAlcohol(selected)} />
                      <Switch title="No junk food (+1pt)" value={bonusesJunkFood} onChange={selected => setBonusesJunkFood(selected)} />
                    </div>
                  </div>

                  <div className="form-section space-y-8">
                    <div>
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Misc</h2>
                      <h3 className="mb-4 text-xs">
                        Create dummy teams as placeholder, in order to send out team links for private clients in email
                      </h3>
                      <div className="space-y-2">
                        <Switch
                          title="Create placeholder teams?"
                          value={createPlaceholderTeams}
                          onChange={selected => setCreatePlaceholderTeams(selected)}
                        />
                      </div>
                    </div>

                    <div>
                      <h2 className="pb-2 mb-4 text-md font-semibold border-b">Workout entry type</h2>
                      <h3 className="mb-4 text-xs">
                        You must select a workout entry type to start a league. By default, entries are set to manual through Moove, but you
                        can also sync with your Health app, or use both options together.
                      </h3>
                      <DropDown
                        label="Workout entry type"
                        placeholder="Please choose workout entry type"
                        value={workoutSource}
                        values={[
                          {
                            key: 'Moove',
                            value: 'Moove (Manual)',
                          },
                          {
                            key: 'Health App',
                            value: 'Health App (Sync)',
                          },
                          {
                            key: 'Moove + Health App',
                            value: 'Moove + Health App',
                          },
                        ]}
                        required={true}
                        onChange={text => setWorkoutSource(text)}
                      />
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn min-w-[120px] mt-4">
                  Save
                </button>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LeagueNew;
